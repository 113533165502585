<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3">Upload Quiz</Text>
      <Icon name="close" @click="close" />
    </List>

    <div el="input-list">
      <label el="input-label">
        <div el="sm-txt label">Date</div>
        <input
          type="date"
          name="input"
          placeholder="Date (Format: MM/DD/YYYY)"
          v-model="quiz.date"
          el
        />
      </label>
      <label el="input">
        <input
          type="text"
          name="input"
          placeholder="Title"
          v-model="quiz.label"
          el
        />
      </label>
      <label el="input">
        <div el="sm-txt label" style="margin-bottom: var(--space-xxxsm)">
          Label
        </div>
        <textarea
          name="textarea1"
          placeholder="Description..."
          cols="30"
          rows="10"
          v-model="quiz.description"
          el
        ></textarea>
      </label>
    </div>
    <Text>Tags</Text>
    <div class="tags">
      <Text
        v-for="item in quiz.tagList"
        :key="item"
        @click="toggleTag(item)"
        :class="{ active: quiz.tags.includes(item) }"
        >{{ item }}</Text
      >
    </div>
    <List el="column-between xxsm">
      <Text>Links</Text>
      <Icon name="add_box" @click="addLink()" />
    </List>
    <List el="xxsm">
      <List el="column xxsm" v-for="item in quiz.attachedLinks" :key="item">
        <input type="text" placeholder="Label" v-model="item.label">
        <input type="text" placeholder="https://..." v-model="item.url">
        <Icon name="close" @click="removeLink(item)" />
      </List>
    </List>
    <!-- <h4 el>Content</h4> -->

    <List el="column-stretch xsm" style="margin-top: 32px">
      <Btn el="2ry" @click="saveQuizDraft" v-if="validInput"
        >Save Draft</Btn
      >
      <Btn el="2ry" disabled v-else>Save Draft</Btn>
      <Btn @click="saveQuiz" v-if="validInput">Save</Btn>
      <Btn disabled v-else>Save</Btn>
    </List>
  </List>
</template>
<script>
// let forEach = require("lodash/forEach");
// let map = require("lodash/map");
import { reactive } from "vue";
import router from "../router";
import Icon from "@/components/btn/Icon.vue";
import { db, auth } from "@/firebase";
import { addDoc, collection } from "firebase/firestore";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      groups: this.$store.state.main.groups,
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      module: null,
      quiz: {
        label: "",
        description: "",
        date: "",
        tags: [],
        tagList: ["JAVA", "automation", "API", "SDLC"],
        // thumbnail: "URL",
        permissions: [],
        attachedLinks: reactive([]),
        status: "publish",
      },
    };
  },
  methods: {
    toggleTag(item) {
      let tags = this.quiz.tags;
      if (tags.includes(item)) {
        let i = tags.indexOf(item);
        if (i > -1) {
          tags.splice(i, 1);
        }
      } else {
        tags.push(item);
      }
    },
    close() {
      this.closeSection();
      router.push("/dashboard/quiz");
    },
    closeSection() {
      this.section.collapse = true;
    },
    addLink(label = '', url = ''){
      let x = this;
      x.quiz.attachedLinks.push({
        label: label,
        url: url,
      });
    },
    removeLink(item) {
      let links = this.quiz.attachedLinks;
      if (links.includes(item)) {
        let i = links.indexOf(item);
        if (i > -1) {
          links.splice(i, 1);
        }
      }
    },
    async saveQuiz() {
      var x = this;
      let d = new Date(`${x.quiz.date} 00:00:00`),
        now = Date.now();
      let data = {
        label: x.quiz.label,
        description: x.quiz.description,
        date: d.toLocaleDateString("en-CA"),
        tags: x.quiz.tags,
        // thumbnail: x.quiz.thumbnail,
        permissions: x.quiz.permissions,
        uploadedOn: now,
        uploadedBy: {
          uid: auth.currentUser.uid,
          displayName: auth.currentUser.displayName,
        },
        attachedLinks: x.quiz.attachedLinks,
        status: x.quiz.status,
        // id: "vid1",
      };
      const docRef = await addDoc(collection(db, "quiz"), data);
      console.log(docRef.id);
      // data.id = docRef.id;
      // x.videosList[docRef.id] = data;
        this.resetQuiz();
        this.closeSection();
    },
    saveQuizDraft() {
      this.quiz.status = "draft";
      this.saveQuiz();
      //   this.closeSection();
    },
    resetQuiz() {
      let r = this.quiz;
      r.label = "";
      r.description = "";
      r.date = "";
      r.tags = ["JAVA"];
      r.permissions = [];
      r.attachedLinks = [];
      r.status = "publish";
      let d = new Date();
      r.date = d.toLocaleDateString("en-CA");
    },
  },
   watch: {
    "groups.active": function (val) {
      this.quiz.tags = [val];
    },
  },
  mounted() {
    let d = new Date();
    this.quiz.date = d.toLocaleDateString("en-CA");
    this.quiz.tags = [this.groups.active];
    this.quiz.tagList = ["JAVA", "automation", "API", "SDLC", ...this.groups.list];
  },
  computed: {
    attachedLinksLen() {
      if (this.quiz.attachedLinks != null) {
        return this.quiz.attachedLinks.length;
      } else {
        return 0;
      }
    },
    validInput() {
      let r = this.quiz;
      if (r.label != "" && this.attachedLinksLen > 0 && r.tags.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
input:not([el]){
    padding: 4px 8px;
    border: 0;
    background-color: #f1f1f1;
    border-radius: 3px;
    font-size: 16px;
    width: 100%;
}
</style>
